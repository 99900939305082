import { useState } from 'react';

import { useAuth } from '../../../hooks/useAuth';
import { useTheme } from '../../../hooks/useTheme';

import { ReactComponent as OrdersIcon } from '../../../icons/orders.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settings.svg';
import { ReactComponent as SignOutIcon } from '../../../icons/sign-out.svg';

import './navigation-popup.scss';

const NavigationPopup = ({ onSignOutClick }) => {
  const { themeConfig } = useTheme();
  const { user } = useAuth();

  const textColor = themeConfig?.primary?.text?.DEFAULT;
  const borderColor = themeConfig?.primary?.border?.DEFAULT;

  return (
    <div
      className="navigation-popup"
      style={{ background: themeConfig?.secondary?.background?.DEFAULT }}
    >
      <div>
        <img src={user?.photoURL} width={48} height={48} alt="User Profile" />
        <div>
          <span style={{ color: textColor }}>{user?.displayName}</span>
          <span style={{ color: textColor }}>{user?.email}</span>
        </div>
      </div>
      <span
        className="navigation-popup__divider"
        style={{ borderTop: `1px solid ${borderColor}` }}
      />
      <NavigationPopupItem name="Settings" icon={SettingsIcon} />
      <NavigationPopupItem name="Orders" icon={OrdersIcon} />
      <span
        className="navigation-popup__divider"
        style={{ borderTop: `1px solid ${borderColor}` }}
      />
      <NavigationPopupItem name="Sign Out" icon={SignOutIcon} onClick={onSignOutClick} />
    </div>
  );
};

const NavigationPopupItem = ({ name, icon: Icon, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const { themeConfig } = useTheme();

  const background = isHover ? themeConfig?.primary?.border?.DEFAULT : 'transparent';
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  return (
    <div
      className="navigation-popup__item"
      style={{ background: background, outline: `1px solid ${background}` }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <span />
      <Icon style={{ color: textColor }} />
      <span style={{ color: textColor }}>{name}</span>
    </div>
  );
};

export default NavigationPopup;
