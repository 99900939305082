import React from 'react';
import { ShimmerTitle } from 'shimmer-effects-react';

import { useMobile } from '../../../hooks/useMobile';

import './menu-list.scss';

import { MenuListItem } from '../item/list/MenuListItem';

export const MenuList = ({ title, items = [], loading = false }) => (
  <>
    {loading && <MenuListGalleryShimmer columns={4} />}
    {!loading && (
      <div className="menu-list">
        <span>{title}</span>
        <div>
          {items.map((item) => (
            <MenuListItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    )}
  </>
);

const MenuListItemShimmer = () => {
  return (
    <div style={{ display: 'flex', gap: '12px', width: '100%' }}>
      <div style={{ display: 'flex', gap: '12px', flexDirection: 'column', width: '80px' }}>
        <ShimmerTitle
          mode="custom"
          from="#ffffff05"
          via="#ffffff10"
          to="#ffffff05"
          line={1}
          height={80}
          rounded={0.5}
        />
      </div>
      <div style={{ display: 'flex', gap: '12px', flexDirection: 'column', flex: 1 }}>
        <ShimmerTitle
          mode="custom"
          from="#ffffff05"
          via="#ffffff10"
          to="#ffffff05"
          line={1}
          rounded={0.4}
          gap={0}
          height={18}
          width={70}
        />
        <ShimmerTitle
          mode="custom"
          from="#ffffff05"
          via="#ffffff10"
          to="#ffffff05"
          line={3}
          rounded={0.4}
          gap={8}
          height={10}
        />
      </div>
    </div>
  );
};

const MenuListGalleryShimmer = ({ columns }) => {
  const mobile = useMobile();
  return (
    <div className="menu-list">
      <span>
        <ShimmerTitle
          mode="custom"
          from="#ffffff05"
          via="#ffffff10"
          to="#ffffff05"
          line={1}
          rounded={0.4}
          gap={0}
          height={29}
          width={mobile ? 40 : 15}
        />
      </span>
      <div>
        {Array.from(Array(columns).keys()).map((key) => (
          <MenuListItemShimmer key={key} />
        ))}
      </div>
    </div>
  );
};
