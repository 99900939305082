import { useTheme } from '../../../hooks/useTheme';

import { ReactComponent as Facebook } from '../../../icons/facebook.svg';
import { ReactComponent as Google } from '../../../icons/google.svg';
import { ReactComponent as LogoFull } from '../../../icons/logo-full.svg';

import './login-options.scss';

import Button from '../../common/button/Button';

const LoginOptions = ({
  onSignInClick,
  onRegisterClick,
  onGoogleActionClick,
  onFacebookActionClick
}) => {
  const { themeConfig } = useTheme();
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  return (
    <div className="login-options">
      <LogoFull
        width={0}
        height={0}
        fill={themeConfig?.primary?.DEFAULT}
        style={{ color: textColor }}
      />
      <div className="login-options__title">
        <span>Welcome to Instant Meals</span>
        <span>Quality food in an instant</span>
      </div>
      <Button onClick={onSignInClick}>SIGN IN</Button>
      <Button onClick={onRegisterClick} outline>
        REGISTER
      </Button>
      <div className="login-options__connect-with">
        <div style={{ background: themeConfig?.secondary?.background?.DEFAULT }} />
        <span style={{ color: textColor }}> OR CONNECT WITH</span>
        <div style={{ background: themeConfig?.secondary?.background?.DEFAULT }} />
      </div>
      <div className="login-options__firebase">
        <Google width={48} height={48} onClick={onGoogleActionClick} />
        <Facebook width={48} height={48} onClick={onFacebookActionClick} />
      </div>
      <div className="login-options__footer">
        <span style={{ color: textColor }}>Terms &amp; Conditions &amp; Privacy Policy</span>
      </div>
    </div>
  );
};

export default LoginOptions;
