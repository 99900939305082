import React from 'react';
import { useSelector } from 'react-redux';
import { ShimmerTitle } from 'shimmer-effects-react';

import { useMobile } from '../../hooks/useMobile';

import { PromotionItem } from './item/PromotionItem';
import './promotions.scss';

export const Promotions = ({ promotions = [] }) => {
  const { loading } = useSelector((state) => state.promotions);

  return (
    <>
      {loading && <PromotionGalleryShimmer columns={2} />}
      {!loading && promotions.length > 0 && (
        <div className="promotions">
          <h2>Promotions</h2>
          <div className="promotions__items">
            {promotions.map((item, index) => (
              <PromotionItem
                key={`Promotion ${index}`}
                discount={item.discount}
                description={item.description}
                imageUrl={item.imageUrl}
                color={item.color}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const PromotionShimmer = () => {
  const mobile = useMobile();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        rounded={0.5}
        gap={0}
        height={mobile ? 135 : 125}
      />
    </div>
  );
};

const PromotionGalleryShimmer = ({ columns }) => {
  return (
    <div className="promotions">
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        rounded={0.4}
        gap={0}
        height={29}
        width={45}
      />
      <div className="promotions__items">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, minMax(0,1fr))',
            gap: '24px',
            width: '100%'
          }}
        >
          {Array.from(Array(columns).keys()).map((key) => (
            <PromotionShimmer key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
