import React from 'react';

import { useMenus } from '../../../hooks/useMenus';

import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';

import './search.scss';

import Input from '../input/Input';

const Search = () => {
  const { query, handleSearch } = useMenus();

  return (
    <div className="search">
      <Input
        placeholder="Search"
        icon={SearchIcon}
        clearIcon={CloseIcon}
        onChange={handleSearch}
        value={query}
      />
    </div>
  );
};

export default Search;
