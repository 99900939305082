import { combineReducers } from 'redux';

import categoryReducer from './category/category.reducer';
import menuReducer from './menu/menu.reducer';
import promotionReducer from './promotion/promotion.reducer';

const rootReducer = combineReducers({
  menu: menuReducer,
  categories: categoryReducer,
  promotions: promotionReducer
});

export default rootReducer;
