export const THEME_DARK = {
  primary: {
    DEFAULT: '#E53935',
    background: '#212121',
    text: {
      DEFAULT: '#FFFFFF',
      variant: '#BDBDBD'
    },
    icon: '#FFFFFF',
    notification: {
      DEFAULT: '#212121',
      variant: '#B3261E'
    },
    navigation: {
      badge: '#616161'
    },
    border: {
      DEFAULT: '#575757'
    }
  },
  secondary: {
    background: {
      DEFAULT: '#424242',
      variant: '#313131'
    }
  },
  status: {
    error: '#E53935',
    warning: '#FFC107',
    info: '#2196F3'
  },
  fire: {
    background: '#212121',
    baseColour1: '#C62828',
    baseColour2: '#EF6C00'
  }
};

export const THEME_DARK_BLUE = {
  primary: {
    DEFAULT: '#E53935',
    background: '#263238',
    text: {
      DEFAULT: '#FFFFFF',
      variant: '#78909C'
    },
    icon: '#FFFFFF',
    notification: {
      DEFAULT: '#263238',
      variant: '#B3261E'
    },
    border: {
      DEFAULT: '#455A64'
    }
  },
  secondary: {
    background: {
      DEFAULT: '#455A64',
      variant: '#2E3C43'
    }
  },
  status: {
    error: '#E53935',
    warning: '#FFC107',
    info: '#2196F3'
  },
  fire: {
    background: '#263238',
    baseColour1: '#C62828',
    baseColour2: '#EF6C00'
  }
};
