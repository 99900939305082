import { useState } from 'react';

import { PurchaseContext } from '../context/PurchaseContext';

export const PurchaseProvider = ({ children }) => {
  const [cart, setCart] = useState(0);

  return (
    <PurchaseContext.Provider
      value={{
        cart,
        setCart
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
