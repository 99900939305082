import React from 'react';
import { useSelector } from 'react-redux';
import { ShimmerDiv, ShimmerTitle } from 'shimmer-effects-react';

import { useMenus } from '../../hooks/useMenus';
import { useMobile } from '../../hooks/useMobile';

import './categories.scss';
import { CategoryItem } from './item/CategoryItem';

export const Categories = ({ categories = [] }) => {
  const { selectedCategory, setSelectedCategory } = useMenus();
  const { loading } = useSelector((state) => state.categories);

  return (
    <>
      {loading && <CategoryGalleryShimmer columns={4} />}
      {!loading && categories.length > 0 && (
        <div className="categories">
          <h2>Categories</h2>
          <div className="categories__items">
            {categories.map((item, index) => (
              <CategoryItem
                key={`Category ${item.id}`}
                id={item.id}
                selected={selectedCategory === item.id}
                name={item.name}
                imageUrl={item.imageUrl}
                onSelect={setSelectedCategory}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const CategoryShimmer = () => {
  const mobile = useMobile();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ShimmerDiv
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        height={mobile ? 56 : 108}
        width={mobile ? 56 : 108}
        rounded={0.5}
      />
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        rounded={0.4}
        gap={0}
        height={18}
      />
    </div>
  );
};

const CategoryGalleryShimmer = ({ columns }) => {
  const mobile = useMobile();
  return (
    <div className="categories">
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        rounded={0.4}
        gap={0}
        height={29}
        width={mobile ? 40 : 15}
      />
      <div className="categories__items">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, minMax(0,1fr))`,
            gap: mobile ? '12px' : '24px'
          }}
        >
          {Array.from(Array(columns).keys()).map((key) => (
            <CategoryShimmer key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
