import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const firebaseMiddleware = () => (next) => (action) => {
  try {
    const auth = getAuth();

    // Set up authentication state listener
    auth.onAuthStateChanged((user) => {
      if (user) {
        const enhancedAction = { ...action, user };
        return next(enhancedAction);
      } else {
        const navigate = useNavigate();
        navigate('/login');
      }
    });
  } catch (error) {
    // eslint-disable-next-line
    console.error('Firebase middleware error:', error);
    return next(action);
  }
};

export default firebaseMiddleware;
