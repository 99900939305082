import './category-item.scss';

export const CategoryItem = ({ id, name, imageUrl, selected, onSelect }) => (
  <div className={`category-item ${selected && 'category-item--selected'}`}>
    <div onClick={() => onSelect(id)}>
      <img src={imageUrl} alt="category" />
    </div>
    <p>{name}</p>
  </div>
);
