import { useLayoutEffect, useRef, useState } from 'react';

import './popup.scss';

const Popup = ({
  isVisible,
  triggerRef,
  onClose,
  children,
  className,
  style,
  offset,
  width,
  height
}) => {
  const [position, setPosition] = useState(offset);
  const popperRef = useRef();

  const calculatePosition = () => {
    if (popperRef.current) {
      const { height } = popperRef.current.getBoundingClientRect();
      setPosition({
        top: -height,
        left: position.left
      });
    }
  };

  useLayoutEffect(() => {
    calculatePosition();
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, [popperRef, isVisible]);

  const handleMouseEnter = () => {
    if (isVisible) {
      calculatePosition();
    }
  };

  const handleMouseLeave = () => {
    onClose();
  };

  return (
    <>
      {isVisible && triggerRef.current && (
        <div
          className="popup"
          style={{
            top: position.top,
            left: position.left,
            width: width ?? '100vw',
            height: height ?? 'auto'
          }}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          <div
            style={{ width: width, height: height, ...style }}
            className={className}
            ref={popperRef}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
