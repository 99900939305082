import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_MENU_CATEGORIES_REQUEST, SET_MENU_CATEGORIES } from './category.action';

import { createGetRequest } from '../tools/tools';

function* getMenuCategories({ user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createGetRequest(user, '/menu/categories');

    const response = yield axios.get(endpoint, requestOptions);

    const categories = [];

    response?.data.forEach((category) => {
      categories.push({ id: category.id, ...category });
    });

    yield put({ type: SET_MENU_CATEGORIES, data: categories, error: undefined });
    if (onComplete) onComplete(categories);
  } catch (error) {
    yield put({ type: SET_MENU_CATEGORIES, data: undefined, error });
    if (onComplete) onComplete(error);
  }
}

export default function* watchCategories() {
  yield takeLatest(GET_MENU_CATEGORIES_REQUEST, getMenuCategories);
}
