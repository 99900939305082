import { ReactComponent as PromotionBackground } from '../../../icons/promotions-bg.svg';

import './promotion-item.scss';

export const PromotionItem = ({ discount, description, imageUrl, color }) => (
  <div className="promotion-item">
    <img src={imageUrl} alt="promotion" />
    <PromotionBackground style={{ color: color }} />
    <span>{discount}%</span>
    <span>Discount</span>
    <span>{description}</span>
  </div>
);
