import Navigation from '../../navigation/Navigation';

const Wallet = () => {
  return (
    <Navigation>
      <div style={{ color: 'white' }}>Wallet</div>
    </Navigation>
  );
};

export default Wallet;
