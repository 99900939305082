import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { AppNavigationContext } from '../context/AppNavigationContext';

const AppNavigationProvider = ({ children }) => {
  const location = useLocation();

  const isCurrentRoute = useCallback(
    (route) => {
      return location.pathname === route;
    },
    [location]
  );

  const memoizedShowCartTotal = useMemo(() => {
    //TODO Feature not implemented
    // return isCurrentRoute(ROUTE_PATH.MENU);
    return false;
  }, []);

  return (
    <AppNavigationContext.Provider
      value={{
        isCurrentRoute,
        showCartTotal: memoizedShowCartTotal
      }}
    >
      {children}
    </AppNavigationContext.Provider>
  );
};

export default AppNavigationProvider;
