import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg';

import './menu-grid-item.scss';

import { Currency } from '../../../../utils/currency/Currency';

export const MenuGridItem = ({ item }) => (
  <div className="menu-grid-item">
    <img src={item.imageUrl} alt={`Menu Grid Item ${item.id}`} />
    <div>
      <div>
        <span className="menu-grid-item__name">{item.name}</span>
        <Currency className="menu-grid-item__price" value={item.price} />
      </div>
      <div>
        <span className="menu-grid-item__description">{item.description}</span>
        <PlusIcon className="menu-grid-item__add" width={24} height={24} />
      </div>
    </div>
  </div>
);
