import { useTheme } from '../../../../hooks/useTheme';

import { ReactComponent as ErrorIcon } from '../../../../icons/error.svg';

import OkDialog from '../ok-dialog/OkDialog';

const ErrorDialog = ({ isOpen, title, message, onClick, onClose }) => {
  const { themeConfig } = useTheme();

  return (
    <OkDialog
      isOpen={isOpen}
      title={title}
      message={message}
      onClick={onClick}
      onClose={onClose}
      icon={<ErrorIcon width={56} height={56} style={{ color: themeConfig?.status?.error }} />}
    />
  );
};

export default ErrorDialog;
