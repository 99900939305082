import { useMenus } from '../../hooks/useMenus';

import { ReactComponent as ContactEmailIcon } from '../../icons/contact-email.svg';
import { ReactComponent as ContactFacebookIcon } from '../../icons/contact-facebook.svg';
import { ReactComponent as ContactPhoneIcon } from '../../icons/contact-phone.svg';
import { ReactComponent as ContactWhatsappIcon } from '../../icons/contact-whatsapp.svg';
import { ReactComponent as ContactXIcon } from '../../icons/contact-x.svg';

import './sidebar.scss';

import { Promotions } from '../promotion/Promotions';

export const Sidebar = () => {
  const { promotions } = useMenus();

  return (
    <div className="sidebar">
      <Promotions promotions={promotions} />
      <div className="sidebar__footer">
        <div className="sidebar__about">
          <span>About Us</span>
          <span>
            At Instant Meals, we make ordering food and customizing your meal plans a breeze. Our
            app offers a variety of delicious options, allowing you to tailor your meals to fit your
            lifestyle. Experience convenience and flavor, all in one place.
          </span>
        </div>
        <div className="sidebar__contact">
          <span>Contact</span>
          <div>
            <ContactFacebookIcon />
            <ContactXIcon />
            <ContactEmailIcon />
            <ContactWhatsappIcon />
            <ContactPhoneIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
