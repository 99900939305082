import Navigation from '../../navigation/Navigation';

const Cart = () => {
  return (
    <Navigation>
      <div style={{ color: 'white' }}>Cart</div>
    </Navigation>
  );
};

export default Cart;
