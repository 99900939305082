import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';

import { FirebaseContext } from '../context/FirebaseContext';

export const FirebaseProvider = ({ children }) => {
  const firebaseConfig = {
    apiKey: 'AIzaSyAkWTGRHrgirgCk83uSaTT8dZyxZqRJnxY',
    authDomain: 'instant-meals.firebaseapp.com',
    projectId: 'instant-meals',
    storageBucket: 'instant-meals.appspot.com',
    messagingSenderId: '816689555837',
    appId: '1:816689555837:web:321a46529c15126160c414',
    measurementId: 'G-10YHM2NS6Z'
  };

  const firebase = initializeApp(firebaseConfig);
  const auth = getAuth(firebase);
  const remoteConfig = getRemoteConfig(firebase);

  return (
    <FirebaseContext.Provider value={{ auth, remoteConfig }}>{children}</FirebaseContext.Provider>
  );
};
