import { GET_MENUS_REQUEST, SET_MENUS } from './menu.action';

const initialState = {
  menus: [],
  loading: false,
  error: undefined
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SET_MENUS:
      return {
        ...state,
        loading: false,
        menus: action.data || [],
        error: action.error || undefined
      };
    default:
      return state;
  }
};

export default menuReducer;
