import { useTheme } from '../../../hooks/useTheme';

import { ReactComponent as Spinner } from '../../../icons/loader.svg';

import './button.scss';

const Button = ({ outline, children, className, onClick, type, style, secondary, loading }) => {
  const { themeConfig } = useTheme();

  const themeStyle = {
    background: `${
      outline
        ? 'transparent'
        : secondary
          ? themeConfig?.secondary?.background?.DEFAULT
          : themeConfig?.primary?.DEFAULT
    }`,
    border: `1px solid ${
      secondary ? themeConfig?.secondary?.background?.DEFAULT : themeConfig?.primary?.DEFAULT
    }`
  };

  return (
    <button
      type={type}
      onClick={onClick}
      style={{ ...themeStyle, ...style }}
      className={`button ${className}`}
    >
      {loading ? <Spinner width={24} height={24} /> : children}
    </button>
  );
};

export default Button;
