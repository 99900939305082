import React from 'react';

import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useAuth } from '../../hooks/useAuth';
import { useMenus } from '../../hooks/useMenus';
import { useMobile } from '../../hooks/useMobile';
import { usePurchase } from '../../hooks/usePurchase';
import { useTheme } from '../../hooks/useTheme';

import { LAYOUT_GRID, LAYOUT_LIST } from '../../provider/MenuProvider';

import { ReactComponent as LayoutGrid } from '../../icons/layout-grid.svg';
import { ReactComponent as LayoutList } from '../../icons/layout-list.svg';

import './header.scss';

import CartTotal from '../cart-total/CartTotal';
import Search from '../common/search/Search';

const Header = () => {
  const { user } = useAuth();
  const { cart } = usePurchase();
  const { showCartTotal } = useAppNavigation();
  const isMobile = useMobile();
  const { menuLayout, setMenuLayout } = useMenus();
  const { themeConfig } = useTheme();
  const backgroundColor = themeConfig?.secondary?.background?.DEFAULT;
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  return (
    <div className="header">
      <div>
        <div className="header__welcome">
          <span>Hi {user?.displayName}</span>
          <span>Welcome Back!</span>
        </div>
        {showCartTotal && <CartTotal total={cart} />}
      </div>
      <div className="header__search">
        {isMobile && (
          <>
            <Search />
            {menuLayout === LAYOUT_GRID && (
              <LayoutList
                key={LAYOUT_GRID}
                onClick={() => setMenuLayout(LAYOUT_LIST)}
                style={{ background: backgroundColor, color: textColor }}
              />
            )}
            {menuLayout === LAYOUT_LIST && (
              <LayoutGrid
                key={LAYOUT_LIST}
                onClick={() => setMenuLayout(LAYOUT_GRID)}
                style={{ background: backgroundColor, color: textColor }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
