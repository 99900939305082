import { GET_PROMOTIONS_REQUEST, SET_PROMOTIONS } from './promotion.action';

const initialState = {
  promotions: [],
  loading: false,
  error: undefined
};

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMOTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SET_PROMOTIONS:
      return {
        ...state,
        loading: false,
        promotions: action.data || [],
        error: action.error || undefined
      };
    default:
      return state;
  }
};

export default promotionReducer;
