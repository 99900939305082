import { useNavigate } from 'react-router-dom';

import { useTheme } from '../../../hooks/useTheme';

import './not-found.scss';

import Button from '../../common/button/Button';
import FireAnimationBackground from '../../fire-animation-background/FireAnimationBackground';

const NotFound = () => {
  const { themeConfig } = useTheme();
  const navigate = useNavigate();

  return (
    <FireAnimationBackground>
      <div className="not-found">
        <div className="not-found__title">
          <span style={{ color: themeConfig?.primary?.text?.DEFAULT }}>4</span>
          <span style={{ color: themeConfig?.primary?.DEFAULT }}>0</span>
          <span style={{ color: themeConfig?.primary?.text?.DEFAULT }}>4</span>
        </div>
        <span style={{ color: themeConfig?.primary?.text?.DEFAULT }}>
          The page you are looking for might have been removed, had its name changed or is
          temporarily unavailable.
        </span>
        <Button
          onClick={() => navigate('/', { replace: true })}
          style={{ border: `1px solid ${themeConfig?.primary?.text?.DEFAULT}` }}
          outline
          className="not-found__button"
        >
          Back to Home
        </Button>
      </div>
    </FireAnimationBackground>
  );
};

export default NotFound;
