import React from 'react';

import { ReactComponent as Cart } from '../../icons/cart_bold.svg';

import './cart-total.scss';

const CartTotal = ({ total }) => {
  return (
    <div className={`cart-total ${total > 0 ? 'cart-total--active' : ''}`}>
      <Cart width={16} height={16} />
      <span>R {total}.00</span>
    </div>
  );
};

export default CartTotal;
