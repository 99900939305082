import AuthProvider from './AuthProvider';
import { FirebaseProvider } from './FirebaseProvider';
import { MenuProvider } from './MenuProvider';
import { PurchaseProvider } from './PurchaseProvider';
import { ThemeContextProvider } from './ThemeProvider';

import { THEME_DARK } from '../stylesheet/theme';

const AppProviders = ({ children }) => (
  <FirebaseProvider>
    <AuthProvider>
      <PurchaseProvider>
        <ThemeContextProvider config={THEME_DARK}>
          <MenuProvider>
            <div
              style={{
                background: `${THEME_DARK?.primary?.background ?? 'white'}`,
                width: '100%',
                height: '100%'
              }}
            >
              {children}
            </div>
          </MenuProvider>
        </ThemeContextProvider>
      </PurchaseProvider>
    </AuthProvider>
  </FirebaseProvider>
);

export default AppProviders;
