import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_PROMOTIONS_REQUEST, SET_PROMOTIONS } from './promotion.action';

import { createGetRequest } from '../tools/tools';

function* getPromotions({ user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createGetRequest(user, '/promotions');

    const response = yield axios.get(endpoint, requestOptions);

    const promotions = [];

    response.data.forEach((promotion) => {
      promotions.push({ id: promotion.id, ...promotion });
    });

    yield put({ type: SET_PROMOTIONS, data: promotions, error: undefined });
    if (onComplete) onComplete(promotions);
  } catch (error) {
    yield put({ type: SET_PROMOTIONS, data: undefined, error });
    if (onComplete) onComplete(error);
  }
}

export default function* watchPromotions() {
  yield takeLatest(GET_PROMOTIONS_REQUEST, getPromotions);
}
