import React from 'react';

import './hero.scss';

const Hero = () => {
  return (
    <div className="hero" style={{ backgroundImage: 'url(/images/hero.jpg)' }}>
      <div>
        <span />
      </div>

      <div>
        <span>
          Meal
          <br />
          Creator
        </span>
        <span>Design your ideal meals effortlessly with our intuitive custom meal builder.</span>
      </div>
    </div>
  );
};

export default Hero;
