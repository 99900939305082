import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { useTheme } from '../../hooks/useTheme';

import { AuthStatus } from '../../provider/AuthProvider';

import AuthDialog from './auth-dialog/AuthDialog';
import LoginOptions from './login-options/LoginOptions';
import './login.scss';
import Register from './register/Register';
import SignIn from './sign-in/SignIn';

import Carousel from '../common/carousel/Carousel';
import FireAnimationBackground from '../fire-animation-background/FireAnimationBackground';

const Login = () => {
  const { themeConfig } = useTheme();
  const {
    pageIndex,
    setPageIndex,
    user,
    signInWithGoogle,
    signInWithEmail,
    registerWithEmail,
    clearFormData,
    clearAuthError,
    authError,
    forgotPassword
  } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const memoizedIsDialogOpen = useMemo(() => {
    return (
      authError !== undefined && authError !== null && authError?.status !== AuthStatus.SUCCESS
    );
  }, [authError]);

  const redirectHome = () => {
    const from = location.state?.from || '/';
    navigate(from, { replace: true });
  };

  if (user) {
    redirectHome();
  }

  const handleLoginOptionsChange = () => {
    setPageIndex(0);
    clearFormData();
  };

  const handleSignInChange = () => {
    setPageIndex(1);
    clearFormData();
  };

  const handleRegisterChange = () => {
    setPageIndex(2);
    clearFormData();
  };

  const handleSignIn = (formData) => {
    signInWithEmail(formData?.username, formData?.password);
    redirectHome();
  };

  const handleRegister = (formData) => {
    registerWithEmail(formData?.username, formData?.password);
    redirectHome();
  };

  const handleForgotPassword = (email) => {
    forgotPassword(email);
  };

  const handleGoogleAuth = async () => {
    await signInWithGoogle();

    redirectHome();
  };

  const handleFacebookAuth = () => {
    //TODO Add facebook login
    redirectHome();
  };

  return (
    <section className="login">
      <AuthDialog
        authError={authError}
        isOpen={memoizedIsDialogOpen}
        onClose={clearAuthError}
        onClick={clearAuthError}
      />
      <FireAnimationBackground>
        <div className="login__overlay" style={{ background: themeConfig?.primary?.background }} />
        <div className="login__content">
          <Carousel index={pageIndex}>
            <LoginOptions
              onSignInClick={handleSignInChange}
              onRegisterClick={handleRegisterChange}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
            <SignIn
              onActionClick={handleRegisterChange}
              onBackPressed={handleLoginOptionsChange}
              onSubmit={handleSignIn}
              onForgotPasswordClick={handleForgotPassword}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
            <Register
              onActionClick={handleSignInChange}
              onBackPressed={handleLoginOptionsChange}
              onSubmit={handleRegister}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
          </Carousel>
        </div>
      </FireAnimationBackground>
    </section>
  );
};

export default Login;
