import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_MENUS_REQUEST, SET_MENUS } from './menu.action';

import { createGetRequest } from '../tools/tools';

function* getMenus({ user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createGetRequest(user, '/menus');

    const response = yield axios.get(endpoint, requestOptions);

    const menus = [];

    response.data.forEach((menu) => {
      menus.push({ id: menu.id, ...menu });
    });

    yield put({ type: SET_MENUS, data: menus, error: undefined });
    if (onComplete) onComplete(response);
  } catch (error) {
    yield put({ type: SET_MENUS, data: undefined, error });
    if (onComplete) onComplete(error);
  }
}

export default function* watchMenus() {
  yield takeLatest(GET_MENUS_REQUEST, getMenus);
}
