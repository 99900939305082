import { Navigate, useLocation } from 'react-router-dom';

import PageLoader from '../component/loader/PageLoader';

import { useAuth } from '../hooks/useAuth';

import AppNavigationProvider from '../provider/AppNavigationProvider';

const ProtectedRoute = ({ children }) => {
  const { user, pageLoading } = useAuth();
  const location = useLocation();

  return (
    <AppNavigationProvider>
      <PageLoader loading={pageLoading}>
        {user ? <>{children}</> : <Navigate to="/login" state={{ from: location }} replace />}
      </PageLoader>
    </AppNavigationProvider>
  );
};

export default ProtectedRoute;
