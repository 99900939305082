import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg';

import './menu-list-item.scss';

import { Currency } from '../../../../utils/currency/Currency';

export const MenuListItem = ({ item }) => (
  <div className="menu-list-item">
    <img src={item.imageUrl} alt={`Menu List Item ${item.id}`} />
    <div>
      <div>
        <span className="menu-list-item__name">{item.name}</span>
        <Currency className="menu-list-item__price" value={item.price} />
      </div>
      <div>
        <span className="menu-list-item__description">{item.description}</span>
        <PlusIcon className="menu-list-item__add" width={24} height={24} />
      </div>
    </div>
  </div>
);
