import React from 'react';
import { ShimmerTitle } from 'shimmer-effects-react';

import { useMobile } from '../../../hooks/useMobile';

import './menu-grid.scss';

import { MenuGridItem } from '../item/grid/MenuGridItem';

export const MenuGrid = ({ title, items = [], loading = false }) => (
  <>
    {loading && <MenuGridGalleryShimmer columns={4} />}
    {!loading && (
      <div className="menu-grid">
        <span>{title}</span>
        <div>
          {items.map((item) => (
            <MenuGridItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    )}
  </>
);

const MenuGridItemShimmer = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '200px' }}>
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        height={100}
        rounded={0.5}
      />
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={1}
        rounded={0.4}
        gap={0}
        height={18}
      />
      <ShimmerTitle
        mode="custom"
        from="#ffffff05"
        via="#ffffff10"
        to="#ffffff05"
        line={2}
        rounded={0.4}
        gap={8}
        height={12}
      />
    </div>
  );
};

const MenuGridGalleryShimmer = ({ columns }) => {
  const mobile = useMobile();
  return (
    <div className="menu-grid">
      <span>
        <ShimmerTitle
          mode="custom"
          from="#ffffff05"
          via="#ffffff10"
          to="#ffffff05"
          line={1}
          rounded={0.4}
          gap={0}
          height={29}
          width={mobile ? 40 : 15}
        />
      </span>
      <div style={{ overflow: 'hidden' }}>
        {Array.from(Array(columns).keys()).map((key) => (
          <MenuGridItemShimmer key={key} />
        ))}
      </div>
    </div>
  );
};
