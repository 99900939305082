import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import firebaseMiddleware from './api/firebaseMiddleware';
import rootReducer from './api/reducers';
import rootSaga from './api/sagas';
import AppProviders from './provider/AppProviders';
import { appRoutes } from './route/AppRoutes';
import './stylesheet/app.scss';

// Initialize redux saga
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(firebaseMiddleware, sagaMiddleware));

sagaMiddleware.run(rootSaga);

const App = () => {
  const router = createBrowserRouter(createRoutesFromElements(appRoutes()));

  return (
    <Provider store={store}>
      <AppProviders>
        <RouterProvider router={router} />
      </AppProviders>
    </Provider>
  );
};

export default App;
