import React from 'react';
import Modal from 'react-modal';

import { useTheme } from '../../../hooks/useTheme';

import { ReactComponent as Close } from '../../../icons/close.svg';

import './dialog.scss';

import Button from '../button/Button';

Modal.setAppElement('#root');

const Dialog = ({
  isOpen,
  icon: Icon,
  title,
  message,
  primaryText,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  onClose
}) => {
  const { themeConfig } = useTheme();

  const modalStyles = {
    content: {
      color: themeConfig?.primary?.text?.DEFAULT,
      background: themeConfig?.primary?.background,
      border: `1px solid ${themeConfig?.primary?.background}`
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      overlayClassName="dialog__overlay"
      className="dialog__content"
    >
      <div style={{ background: themeConfig?.primary?.background }} className="dialog">
        <div>
          <Close
            width={24}
            height={24}
            style={{ color: themeConfig?.primary?.text?.DEFAULT }}
            onClick={onClose}
          />
        </div>
        {Icon && Icon}
        {title && <h1 style={{ color: themeConfig?.primary?.text?.DEFAULT }}>{title}</h1>}
        {message && (
          <span style={{ color: themeConfig?.primary?.text?.DEFAULT?.variant }}>{message}</span>
        )}
        <div>
          {primaryText && <Button onClick={onPrimaryClick}>{primaryText}</Button>}
          {secondaryText && (
            <Button onClick={onSecondaryClick} secondary>
              {secondaryText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;
