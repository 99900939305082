import Dialog from '../Dialog';

const OkDialog = ({ isOpen, title, message, icon, onClick, onClose }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      message={message}
      secondaryText="OK"
      onSecondaryClick={onClick}
      onClose={onClose}
      icon={icon}
    />
  );
};

export default OkDialog;
