import { useTheme } from '../../../hooks/useTheme';

import './input.scss';

const Input = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  required,
  icon: Icon,
  clearIcon: ClearIcon
}) => {
  const { themeConfig } = useTheme();
  const backgroundColor = themeConfig?.secondary?.background?.DEFAULT;
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  return (
    <div className="form-input" style={{ background: backgroundColor, color: textColor }}>
      <label htmlFor={name}>{label}</label>
      {Icon && <Icon width={20} height={20} style={{ color: themeConfig?.primary?.icon }} />}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        required={required}
      />
      {ClearIcon && value && (
        <ClearIcon
          width={20}
          height={20}
          style={{ color: themeConfig?.primary?.icon }}
          onClick={() => onChange({ name, value: '' })}
        />
      )}
    </div>
  );
};

export default Input;
