import { useTheme } from '../../hooks/useTheme';

import { ReactComponent as Spinner } from '../../icons/loader.svg';

import './page-loader.scss';

const PageLoader = ({ loading, children }) => {
  const { themeConfig } = useTheme();

  if (loading) {
    return (
      <div className="page-loader">
        <Spinner width={48} height={48} style={{ color: themeConfig?.primary?.DEFAULT }} />
      </div>
    );
  }

  return <>{children}</>;
};

export default PageLoader;
